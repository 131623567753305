import { useCallback, useMemo } from 'react';
import { NextRouter, useRouter } from 'next/router';
import {
  getParamsFromRouter,
  deleteAllParamsWithPrefix,
  replaceExistingParamsWithPrefixedParams,
} from '@deliveryhero/gfs-ui';
import { useToast } from '@chakra-ui/react';
import {
  urlSearchParamsToUrl,
  urlSearchParamsToUrlWithPathname,
} from '../../helpers/url';
import {
  MODAL_URL_PARAMS_PREFIX,
  getModalParams,
  getRawModalParamKey,
  unsafeParsePrefixedParam,
  getFlatModalUrlParams,
} from './helpers';
import { ModalConfig } from './types';
import { useGtmModalActions } from './useGtmModalActions';

export const useUrlParamsModal = (config: ModalConfig, router?: NextRouter) => {
  const globalRouter = useRouter();
  const routerParams = useMemo(
    () => getParamsFromRouter(router ?? globalRouter),
    [router, globalRouter],
  );
  const toast = useToast();
  const gtmActions = useGtmModalActions(config.gtm);

  const onParamsParseError = useCallback(() => {
    toast({
      title: 'Please reload',
      description: 'Url is invalid or modified manually',
      status: 'error',
    });
  }, [toast]);

  const isModalOpen = useMemo(() => {
    try {
      const currentModalId = unsafeParsePrefixedParam(
        routerParams.get(`${MODAL_URL_PARAMS_PREFIX}modal.id`),
      );
      return currentModalId === config.id;
    } catch (error) {
      onParamsParseError();
      return false;
    }
  }, [routerParams, config.id, onParamsParseError]);

  const openWithRouterParams = async (
    additionalParams: Record<string, unknown> = {},
  ) => {
    const flatModalUrlParams = getFlatModalUrlParams(
      config.id,
      additionalParams,
    );
    const updatedParams = replaceExistingParamsWithPrefixedParams(
      router ?? globalRouter,
      flatModalUrlParams,
      MODAL_URL_PARAMS_PREFIX,
    );

    if (
      router &&
      updatedParams &&
      updatedParams !== urlSearchParamsToUrl(routerParams)
    ) {
      if (config.onOpen) {
        config.onOpen();
      }

      gtmActions.onOpen();

      await router.push(urlSearchParamsToUrl(updatedParams), undefined, {
        shallow: true,
      });
    }
  };

  const closeWithRouterParams = useCallback(async () => {
    const updatedParams = deleteAllParamsWithPrefix(
      router ?? globalRouter,
      MODAL_URL_PARAMS_PREFIX,
    );

    let updatedParamsOnClose;

    if (router) {
      if (config.onClose) {
        updatedParamsOnClose = config.onClose(updatedParams);
      }
      gtmActions.onClose();

      await router.push(
        urlSearchParamsToUrlWithPathname(
          router,
          updatedParamsOnClose ?? updatedParams,
        ),
        undefined,
        {
          shallow: true,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerParams, config, gtmActions]);

  const getModalData = useCallback(<Data extends Record<string, unknown>>() => {
    return getModalParams<Data>(
      routerParams,
      onParamsParseError,
      getRawModalParamKey,
    );
  }, [routerParams, onParamsParseError]);

  return {
    isOpen: isModalOpen,
    actions: {
      openModal: openWithRouterParams,
      closeModal: closeWithRouterParams,
      getModalData,
    },
  };
};
