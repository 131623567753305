/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

/**
 * @see https://github.com/microsoft/TypeScript/issues/29729#issuecomment-505826972
 */
export type LiteralUnion<T extends U, U = string> = T | (U & {});

export type StringWithSuggestion<T> = T | (string & {});

/**
 * `Partial` for nested objects
 */
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

/**
 * Extend and overwrite config objects
 */
export type RecursiveExtend<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursiveExtend<U>[]
    : T[P] extends object
    ? RecursiveExtend<T[P]>
    : T[P];
} & {
  [x: string]: any;
};

export type InferFullType<T> = T extends RecursivePartial<infer R> ? R : never;

/** Union of primitives to skip with deep omit utilities. */
type Primitive =
  | string
  | Function
  | number
  | boolean
  | symbol
  | undefined
  | null;

/** Deeply omit members of an array of interface or array of type. */
export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

/**
 * Deeply omit members of an interface or type
 * @see https://gist.github.com/ahuggins-nhs/826906a58e4c1e59306bc0792e7826d1
 */
export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP, K> // Array special handling
          : DeepOmit<TP, K>
        : never;
    };

/**
 * Removes `readonly` from `as const` types
 * @see https://stackoverflow.com/a/43001581
 */
export type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

export type ParametersExceptFirst<F> = F extends (
  arg0: any,
  ...rest: infer R
) => any
  ? R
  : never;

type AllKeys<T> = T extends any ? keyof T : never;

type PickType<T, K extends AllKeys<T>> = T extends { [k in K]?: any }
  ? T[K]
  : never;

export type PickTypeOf<
  T,
  K extends string | number | symbol,
> = K extends AllKeys<T> ? PickType<T, K> : never;

/**
 * This is used to merge a union of object types into 1 single type (without adding `| undefined`)
 * @see https://dev.to/lucianbc/union-type-merging-in-typescript-9al
 */
export type MergeUnion<T extends object> = {
  [k in AllKeys<T>]: PickTypeOf<T, k>;
};

export type MergeBy<T, K> = Omit<T, keyof K> & K;

type UndefinedProperties<T> = {
  [P in keyof T]: undefined extends T[P] ? P : never;
}[keyof T];

type ToOptionalMap<T> = {
  [P in keyof T]: ToOptional<T[P]>;
};

/**
 * Converts `{ key: T | undefined }` to `{ key?: T }`
 *
 * Modified from below SO answer
 * @see https://stackoverflow.com/a/56146934
 */
export type ToOptional<T> = T extends Primitive
  ? T
  : T extends any[]
  ? ToOptionalMap<T>
  : Partial<ToOptionalMap<Pick<T, UndefinedProperties<T>>>> &
      ToOptionalMap<Omit<T, UndefinedProperties<T>>>;

/**
 * Opposite of NonNullable
 */
export type Nullable<T> = T | undefined | null;

export type NullableMap<T> = {
  [P in keyof T]: Nullable<T[P]>;
};

/**
 * `Partial` + Nullable for nested objects
 */
export type RecursiveNullable<T> = T extends Primitive
  ? Nullable<T>
  : T extends (infer U)[]
  ? Nullable<RecursiveNullable<U>[]>
  : Nullable<{
      [P in keyof T]?: RecursiveNullable<T[P]>;
    }>;

export type NonFalsy<T> = T extends false | '' | 0 | null | undefined
  ? never
  : T;

export function falsyFilterPredicate<T>(x: T): x is NonFalsy<T> {
  return Boolean(x);
}

export function nullableFilterPredicate<T>(x: T): x is NonNullable<T> {
  return x != null;
}

export function assertUnreachable(x: never): never {
  throw new Error(`Didn't expect to get here: "${x}"`);
}

export type MapKeys<P extends string, T extends Record<string, any>> = {
  [K in Extract<keyof T, string> as `${P}.${K}`]: T[K];
};

/**
  * This type utility removes empty objects from a union type.

  * **Usage:**

  type MyUnion = { a: string; b: number } | { c: boolean } | {}; 
  type NonEmptyUnion = NonEmpty<MyUnion>; // NonEmptyUnion will be { a: string; b: number } | { c: boolean }
*/
export type UnionWithNonEmptyObjects<T> = T extends {}
  ? {} extends T
    ? never
    : T
  : T;
