/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const leaseSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper Lease ID"
  },
  {
    "name": "partnerId",
    "type": "STRING",
    "description": "Pepper Partner ID"
  },
  {
    "name": "facilityId",
    "type": "STRING",
    "description": "Pepper Facility ID"
  },
  {
    "name": "status",
    "type": "STRING",
    "description": "Lease status enum: Draft, Signed"
  },
  {
    "name": "stallIds",
    "type": "STRING",
    "mode": "REPEATED",
    "description": "Stall IDs"
  },
  {
    "name": "priceInfo",
    "type": "RECORD",
    "mode": "NULLABLE",
    "fields": [
      {
        "name": "rentRevenueLocal",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": ""
      },
      {
        "name": "discountLocal",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": ""
      },
      {
        "name": "incrementalCommissionPercentage",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "The % of commission GFS takes on top of the platform commission, this could be 0 in favor of `fixedIncrementalCommissionLocal`. Value b/w 0 to 100"
      },
      {
        "name": "nonDHCommissionPercentage",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Commission for other platforms since KSA is not exclusive. Only for KSA. Value b/w 0 to 100"
      },
      {
        "name": "pickupCommissionPercentage",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Only for KSA. Value b/w 0 to 100"
      },
      {
        "name": "fixedIncrementalCommissionLocal",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Fixed amount of commission GFS takes, could be in addition to or replace `incrementalCommissionPercentage`.Only for KS"
      },
      {
        "name": "internetPriceLocal",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Only for KS"
      },
      {
        "name": "gTSubscriptionLocal",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Utility subscription like water, gas, etc. Only for KSA"
      },
      {
        "name": "chemicalsLocal",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Only for KSA"
      }
    ]
  },
  {
    "name": "termInMonths",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Duration of lease in months"
  },
  {
    "name": "files",
    "type": "STRING",
    "mode": "REPEATED",
    "description": "List of URLs related to lease like contracts, company info, etc"
  },
  {
    "name": "signDate",
    "type": "TIMESTAMP",
    "mode": "NULLABLE"
  },
  {
    "name": "commencementDate",
    "type": "TIMESTAMP",
    "description": "Agreed go live date",
    "mode": "NULLABLE"
  },
  {
    "name": "contractEffectiveDate",
    "type": "TIMESTAMP",
    "mode": "NULLABLE",
    "description": "Rent commencement date"
  },
  {
    "name": "goLiveDate",
    "type": "TIMESTAMP",
    "mode": "NULLABLE"
  },
  {
    "name": "churnDate",
    "type": "TIMESTAMP",
    "mode": "NULLABLE",
    "description": "TODO: Not implemented yet"
  },
  {
    "name": "confidence",
    "type": "RECORD",
    "mode": "NULLABLE",
    "fields": [
      {
        "name": "signing",
        "type": "STRING",
        "description": "Confidence of BD on the sign date of the contract while the lead is in the pipeline",
        "mode": "NULLABLE"
      },
      {
        "name": "commencement",
        "type": "STRING",
        "description": "Confidence of BD on the agreed go live date of partner in the kitchen while the lead is in the pipeline",
        "mode": "NULLABLE"
      }
    ]
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When lease was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When lease was last updated in Pepper"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When lease was last exported from Pepper"
  }
] as const;
export type LeaseSchema = typeof leaseSchemaFields;