/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const orderSchemaFields = [
  {
    "name": "order_id",
    "type": "STRING"
  },
  {
    "name": "global_entity_id",
    "type": "STRING"
  },
  {
    "name": "vendor_id",
    "type": "STRING"
  },
  {
    "name": "facilityId",
    "type": "STRING"
  },
  {
    "name": "stallIds",
    "type": "STRING",
    "mode": "REPEATED",
    "description": "Stall IDs"
  },
  {
    "name": "partnerId",
    "type": "STRING",
    "description": "Pepper Partner ID",
    "mode": "NULLABLE"
  },
  {
    "name": "timestamp",
    "type": "TIMESTAMP"
  },
  {
    "name": "status",
    "type": "STRING"
  },
  {
    "name": "timestamps",
    "type": "RECORD",
    "fields": [
      {
        "name": "createdAt",
        "description": "When Pepper first received any event/update about this order. Custom Pepper timestamp",
        "type": "TIMESTAMP"
      },
      {
        "name": "updatedAt",
        "description": "When Pepper last received any event/update about this order. Custom Pepper timestamp",
        "type": "TIMESTAMP"
      },
      {
        "name": "placedAt",
        "type": "TIMESTAMP",
        "description": "Timestamp when the order was placed on platform (if we had the Order event, otherwise the timestamp of the first event we received). Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "acceptedAt",
        "type": "TIMESTAMP",
        "description": "Order is accepted by the vendor (for example in GO Droid). Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "readyForPackingAt",
        "type": "TIMESTAMP",
        "description": "Order is marked as ready for packing in Cooking App (Cooking Time can be calculated from this). Pepper custom timestamp",
        "mode": "NULLABLE"
      },
      {
        "name": "preparationTimeEstimate",
        "type": "TIMESTAMP",
        "description": "Time Logistics Tracking API 2 expecting the vendor to cook the order (if courier was assigned, otherwise uses kitchen’s default prep time).  Timestamp from the Hurrier (Logistics Tracking API 2 https://product.deliveryhero.net/logistics-rider/documentation/tracking-api-v2-tracking-time-model/)",
        "mode": "NULLABLE"
      },
      {
        "name": "preparedAt",
        "type": "TIMESTAMP",
        "description": "Order is prepared. When ReadyForPickup event is received, or staff marks the order as ready in Pepper. Timestamp from DataStreams or Pepper (See sources.preparedAt for data source)",
        "mode": "NULLABLE"
      },
      {
        "name": "pickupTimeEstimate",
        "type": "TIMESTAMP",
        "description": "Time which App communicating to the customer the rider will pick up the food at the vendor. Timestamp from the Hurrier (Logistics Tracking API 2 https://product.deliveryhero.net/logistics-rider/documentation/tracking-api-v2-tracking-time-model/)",
        "mode": "NULLABLE"
      },
      {
        "name": "pickedUpAt",
        "type": "TIMESTAMP",
        "description": "Order is picked up by rider or marked as PickedUp by Runner in case of vendor delivery. Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "deliveredAt",
        "type": "TIMESTAMP",
        "description": "Rider has completed his delivery in Roadrunner. Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "promisedCustomerAt",
        "type": "TIMESTAMP",
        "description": "Time which was initially promised to the customer when the food could potentially be delivered or picked up. For preorder orders, it should match the timestamp selected by customer when order should be delivered. Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "closedAt",
        "type": "TIMESTAMP",
        "description": "Order is closed. Automatically Closed by Pepper due to being a picked_up order or due to missed events. Custom Pepper timestamp",
        "mode": "NULLABLE"
      },
      {
        "name": "deliveryGeofenceEnteredAt",
        "type": "TIMESTAMP",
        "description": "Earliest timestamp (of all deliveries) when the Rider has entered the 30m geofence. Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "deliveryGeofenceExitedAt",
        "type": "TIMESTAMP",
        "description": "Earliest timestamp (of all deliveries) when the Rider has exited the 30m geofence. Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "riderArrivalAtVendorEstimate",
        "type": "TIMESTAMP",
        "description": "Estimated rider's arrival time at vendor. Timestamp from DataStreams",
        "mode": "NULLABLE"
      },
      {
        "name": "atCounterAt",
        "type": "TIMESTAMP",
        "description": "Timestamp when the Order was marked at_counter. Custom Pepper timestamp.",
        "mode": "NULLABLE"
      }
    ]
  },
  {
    "name": "data",
    "type": "RECORD",
    "fields": [
      {
        "name": "preorder",
        "type": "BOOLEAN",
        "mode": "NULLABLE"
      },
      {
        "name": "bigOrder",
        "type": "BOOLEAN"
      },
      {
        "name": "cancellationReason",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "cancelledBy",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Enum: 'VENDOR'/'CUSTOMER'/'TRANSPORT'/'PLATFORM'/'FULFILMENT'"
      },
      {
        "name": "shortCode",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "totalBags",
        "type": "INTEGER",
        "mode": "NULLABLE"
      },
      {
        "name": "pickupStation",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "paymentMethod",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "totalOrderValue",
        "type": "FLOAT"
      },
      {
        "name": "deliveryProvider",
        "type": "STRING"
      },
      {
        "name": "customColumn1",
        "type": "RECORD",
        "mode": "NULLABLE",
        "fields": [
          {
            "name": "name",
            "type": "STRING"
          },
          {
            "name": "value",
            "type": "STRING",
            "mode": "NULLABLE"
          }
        ]
      },
      {
        "name": "customColumn2",
        "type": "RECORD",
        "mode": "NULLABLE",
        "fields": [
          {
            "name": "name",
            "type": "STRING"
          },
          {
            "name": "value",
            "type": "STRING",
            "mode": "NULLABLE"
          }
        ]
      }
    ]
  },
  {
    "name": "sources",
    "type": "RECORD",
    "fields": [
      {
        "name": "preparationTimeEstimate",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "preparedAt",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "totalBags",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "pickupStation",
        "type": "STRING",
        "mode": "NULLABLE"
      },
      {
        "name": "pickedUpAt",
        "type": "STRING",
        "mode": "NULLABLE"
      }
    ]
  }
] as const;
export type OrderSchema = typeof orderSchemaFields;