/* eslint max-lines: ["error", { "max": 450 }] */
import React, { ComponentProps, useCallback, useId, useMemo } from 'react';
import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  InputGroup,
  SystemStyleObject,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  Select,
  SelectComponentsConfig,
  chakraComponents,
  components as defaultComponents,
  CreatableSelect,
  Props as ReactSelectProps,
  OptionBase,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  SingleValueProps,
  MultiValueProps,
  PlaceholderProps,
  GroupBase,
  MultiValueRemoveProps,
  OptionProps,
  ChakraStylesConfig,
} from 'chakra-react-select';
import { useTranslation } from 'next-i18next';
import { Control, useController } from 'react-hook-form';
import { FormCommonProps, formForwardProps } from '../../utils/forwardProps';
import { FormErrorIcon, Icon } from '../Icon';
import { LabelValueObj as LabelValue } from '../Radio/Radio';
import { chakraStyles } from './chakraStyes';

export type SelectOption<Options = string> = LabelValue<Options> &
  OptionBase & {
    /**
     * Render meta instead of `label` if present
     */
    meta?: React.ReactElement;
  };

type BaseType<Options, IsMulti extends boolean> = Omit<
  ReactSelectProps<SelectOption<Options>, IsMulti>,
  'onChange' | 'isMulti' | 'defaultValue'
> &
  Omit<FormControlProps, 'onChange' | 'defaultValue' | 'defaultChecked'>;

/**
 * @package
 */
export interface BaseFormSelectProps<Options, IsMulti extends boolean>
  extends Omit<BaseType<Options, IsMulti>, 'chakraStyles' | 'components'>,
    FormCommonProps {
  name: string;
  isMulti: IsMulti;
  defaultValue?: IsMulti extends true ? Options[] : Options;
  onChange?: (
    selected: IsMulti extends true ? Options[] : Options | null,
  ) => void;
  components: {
    SingleValue?: React.FC<SingleValueProps>;
    MultiValue?: React.FC<MultiValueProps>;
    MultiValueRemove?: React.FC<MultiValueRemoveProps>;
    DropdownIndicator?: React.FC<DropdownIndicatorProps> | null;
  };
  valueMapper: (
    selected: IsMulti extends true ? Options[] : Options | null,
  ) => IsMulti extends true
    ? SelectOption<Options>[]
    : SelectOption<Options> | null;
  onChangeMapper: (
    selected: IsMulti extends true
      ? SelectOption<Options>[]
      : SelectOption<Options> | null,
  ) => IsMulti extends true ? Options[] : Options | null;
  isCreatable?: boolean;
  showPlaceholder?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  chakraStyles?: ChakraStylesConfig<SelectOption<any>, boolean>;
  labelSx?: SystemStyleObject;
}

const PlaceholderWithLabel = ({
  fieldId,
  label,
  isLabelInline,
  children,
  color,
  ...props
}: {
  isLabelInline?: boolean;
  fieldId?: string;
  label?: string;
  color?: string;
} & PlaceholderProps<any>) => (
  <chakraComponents.Placeholder {...props}>
    {isLabelInline && label ? (
      <Text as="label" htmlFor={fieldId} color={color || 'gray.700'}>
        {label}
        &nbsp;
      </Text>
    ) : null}
    {children}
  </chakraComponents.Placeholder>
);

const OptionWithDesc = ({
  children,
  ...props
}: OptionProps<any> & {
  data: {
    meta?: React.ReactElement;
  };
}) => (
  <chakraComponents.Option {...props}>
    <Flex flexDirection="column" w="100%">
      {props.data.meta ? props.data.meta : <Text>{children}</Text>}
    </Flex>
  </chakraComponents.Option>
);

const CustomDropdownIndicator = ({
  isDisabled,
  ...props
}: { isDisabled?: boolean } & DropdownIndicatorProps<any>) => (
  <defaultComponents.DropdownIndicator
    {...props}
    isDisabled={isDisabled}
    innerProps={{
      ...props.innerProps,
      style: {
        ...props.innerProps.style,
        paddingTop: 0,
        paddingBottom: 0,
      },
    }}
  >
    <Icon color={isDisabled ? 'gray.600' : 'gray.900'} image="dropdown" />
  </defaultComponents.DropdownIndicator>
);

const ClearIndicator: React.FC<ClearIndicatorProps<any>> = ({
  isFocused,
  ...props
}) => (
  // @ts-expect-error react-select expects div
  <IconButton
    {...props.innerProps}
    minW={0}
    pl={1.5}
    py={2}
    mr={-1.5}
    variant="link"
    data-focused={isFocused ? true : undefined}
    aria-label="Clear selected options"
  >
    <Icon color="gray.900" image="erase-filter" />
  </IconButton>
);

const MultiValueLabel = ({
  children,
  data,
  innerProps,
  sx,
}: ComponentProps<typeof chakraComponents.MultiValueLabel>) => {
  return (
    <Tooltip label={data.label}>
      <Text as="span" {...innerProps} sx={sx}>
        {children}
      </Text>
    </Tooltip>
  );
};

/**
 * @package
 */
// eslint-disable-next-line react/function-component-definition
export function BaseFormSelect<
  Options = string,
  IsMulti extends boolean = false,
>({
  name,
  label,
  isLabelInline,
  showPlaceholder = !isLabelInline && !!label,
  placeholder: placeholderOverride,
  defaultValue,
  help,
  onChange,
  valueMapper,
  onChangeMapper,
  options,
  control,
  isCreatable,
  isSearchable = true,
  components: {
    SingleValue = chakraComponents.SingleValue,
    MultiValue = chakraComponents.MultiValue,
    MultiValueRemove = chakraComponents.MultiValueRemove,
    DropdownIndicator = CustomDropdownIndicator,
  } = {},
  chakraStyles: additionalStyles,
  labelSx,
  ...props
}: BaseFormSelectProps<Options, IsMulti>): JSX.Element {
  // TODO: GKS-2176 Chakra handles this internally now

  const id = useId();
  const fieldId = `form-select-${name}-${id}`;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const { t } = useTranslation();
  const placeholder = showPlaceholder
    ? placeholderOverride || t('common:select.placeholder', 'Select')
    : '';
  const errMsg = Array.isArray(error)
    ? error?.map((err) => err.message).join(' ')
    : error?.message;

  const onChangeWrapper = useCallback(
    (newValue: any) => {
      field.onChange(onChangeMapper(newValue));
      onChange?.(onChangeMapper(newValue));
    },
    [field, onChange, onChangeMapper],
  );

  const Component = isCreatable ? CreatableSelect : Select;
  const { isLoading, isDisabled, isReadOnly } = props;
  const components: SelectComponentsConfig<
    Options,
    IsMulti,
    GroupBase<Options>
  > = useMemo(() => {
    return {
      ClearIndicator,
      DropdownIndicator,
      IndicatorSeparator: null,
      /* eslint-disable react/no-unstable-nested-components */
      MultiValueRemove: (multiValueRemoveProps) => (
        // @ts-ignore
        <MultiValueRemove
          {...multiValueRemoveProps}
          innerProps={{
            // eslint-disable-next-line react/destructuring-assignment
            ...multiValueRemoveProps.innerProps,
            // eslint-disable-next-line react/destructuring-assignment
            onClick: multiValueRemoveProps.selectProps.isDisabled
              ? undefined
              : // eslint-disable-next-line react/destructuring-assignment
                multiValueRemoveProps.innerProps.onClick,
          }}
        />
      ),
      Placeholder: (placeholderProps) => (
        <PlaceholderWithLabel
          {...placeholderProps}
          fieldId={fieldId}
          label={label}
          isLabelInline={isLabelInline}
          color={props.placeholderColor}
        />
      ),
      SingleValue: (singleProps) => (
        <SingleValue
          {...singleProps}
          innerProps={{
            // eslint-disable-next-line react/destructuring-assignment
            ...singleProps.innerProps,
            'aria-readonly': isReadOnly,
          }}
          // @ts-expect-error custom component passed via main components
          fieldId={fieldId}
          label={label}
          isLabelInline={isLabelInline}
        />
      ),
      MultiValue: (multiProps) => (
        <MultiValue
          {...multiProps}
          innerProps={{
            // eslint-disable-next-line react/destructuring-assignment
            ...multiProps.innerProps,
            'aria-readonly': isReadOnly,
          }}
          // @ts-expect-error custom component passed via main components
          fieldId={fieldId}
          label={label}
          isLabelInline={isLabelInline}
        />
      ),
      MultiValueLabel,
      Option: (optionProps) => <OptionWithDesc {...optionProps} />,
      /* eslint-enable react/no-unstable-nested-components */
    } as SelectComponentsConfig<Options, IsMulti, GroupBase<Options>>;
  }, [
    MultiValueRemove,
    fieldId,
    label,
    isReadOnly,
    isLabelInline,
    props.placeholderColor,
    SingleValue,
    MultiValue,
    DropdownIndicator,
  ]);

  const { formControlProps, rest } = formForwardProps(props);

  const chakraStylesProp = useMemo(
    () => ({
      ...chakraStyles,
      ...additionalStyles,
    }),
    [additionalStyles],
  );

  return (
    <FormControl
      {...formControlProps}
      // react-select doesn't take testids so passing to control instead
      data-testid={rest['data-testid']}
      isInvalid={!!error}
    >
      {label && !isLabelInline ? (
        <FormLabel sx={labelSx} htmlFor={fieldId}>
          {label}
        </FormLabel>
      ) : null}
      <InputGroup>
        <Component<SelectOption<Options>, IsMulti>
          size="sm"
          inputId={fieldId}
          options={options}
          isSearchable={isSearchable}
          backspaceRemovesValue={isSearchable}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          isDisabled={isLoading || isDisabled || isReadOnly}
          chakraStyles={chakraStylesProp}
          placeholder={placeholder}
          // @ts-expect-error fix select types
          components={components}
          /**
           * To change z-index of dropdown
           * @see https://stackoverflow.com/a/63898744
           */
          menuPortalTarget={
            typeof window !== 'undefined' ? document?.body : undefined
          }
          classNamePrefix="form-select"
          {...rest}
          {...field}
          value={valueMapper(field.value)}
          onChange={onChangeWrapper}
          instanceId={fieldId}
        />
      </InputGroup>
      {/* hide help text if error msg is shown */}
      {!help || errMsg ? null : <FormHelperText>{help}</FormHelperText>}
      <FormErrorMessage>
        <FormErrorIcon />
        {errMsg}
      </FormErrorMessage>
    </FormControl>
  );
}
