import { ChakraStylesConfig } from 'chakra-react-select';
import type { SelectOption } from './BaseSelect';

export const chakraStyles: ChakraStylesConfig<SelectOption<any>, boolean> = {
  container: (provided, _state) => ({
    ...provided,
    w: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    py: state.isMulti && state.hasValue ? 1 : 2,
    h: state.isMulti && state.hasValue ? 'auto' : 9,
    minHeight: 9,
    maxHeight: state.isMulti && state.hasValue ? 'unset' : 9,
    paddingInline: 0,
    alignItems: 'center',
  }),
  input: (provided, _state) => ({
    ...provided,
    h: 5,
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    fontWeight: 400,
    _readOnly: {
      color: 'pepperBlack',
    },
  }),
  multiValue: (provided, _state) => ({
    ...provided,
    fontWeight: 400,
    h: 3,
    _readOnly: {
      color: 'pepperBlack',
      '.form-select__multi-value__remove': {
        display: 'none',
      },
    },
  }),
  multiValueLabel: (provided, _state) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow:
      state.selectProps.menuIsOpen || state.selectProps.isMulti
        ? 'hidden'
        : 'visible',
    p: 0,
    h: state.isMulti ? undefined : 5,
    pl: 3,
    'input[aria-readonly]': {
      display: 'none',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    pl: 1.5,
    isDisabled: state.isDisabled,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderColor: 'gray.500',
    ...(state.isMulti
      ? {
          position: 'unset',
        }
      : null),
  }),
  menuList: (provided, _state) => ({
    ...provided,
    p: 1,
    minW: 'auto',
    borderRadius: 'md',
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    color: 'gray.700',
  }),
  inputContainer: (provided, _state) => ({
    ...provided,
    h: 5,
  }),
  noOptionsMessage: (provided, _state) => ({
    ...provided,
    color: 'pepperBlack',
  }),
  option: (provided, state) => ({
    ...provided,
    m: 1,
    color: state.isSelected ? 'blue.700' : 'pepperBlack',
    borderRadius: 'md',
    width: 'inherit',
    background: state.isSelected ? 'blue.100' : 'white',
    ...(!state.isSelected
      ? {
          ...(state.isFocused
            ? {
                color: 'pepperBlack',
                background: 'gray.50',
              }
            : null),
          _hover: {
            color: 'pepperBlack',
            background: 'gray.50',
          },
        }
      : null),
  }),
};
